<template>
  <div class="appdetail">
    <div class="appdetail_top">
      <div class="info_left">
        <img :src="info.pictureUrl" @error="imageLoadError" />
      </div>

      <div class="info_right">
        <div class='info-title'>{{ info.name }}</div>
        <div class='info-companyName'>{{ companyName||'' }}</div>
        <el-tooltip placement="top" effect="light" v-if="info.introduce&&info.introduce.length>201">
          <template slot="content">
            <span class="introduce_tooltip">{{info.introduce}}</span>
          </template>
          <div class="info-intrudce">{{ info.introduce }}</div>
        </el-tooltip>
        <div v-else class="info-intrudce">{{ info.introduce }}</div>
        <div class='info-bottom'>
          <div class='app-tag'>{{ info.appTypeName }}</div>
        </div>
      </div>
      <el-button class="app_detail_btn" @click='goUse'>立即体验</el-button>
      <div class="account_wrap">
        <img class="account_test" src="@/assets/images/account.png" alt="">
        <div class="account_list" v-if="info.appDetailAddVo">
          <div class="account_item" v-for="(item,index) in info.appDetailAddVo.passwords" :key="index">
            <div class="username">
              <div class="label">用户名： </div>
              <div class="value">{{ item.account }}</div>
            </div>
            <div class="password">
              <div class="label">密码：</div>
              <div class="value">{{ item.password }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="category">
        <div class="item b" v-for="item in info.sceneTagNames" :key="item">{{item}}</div>
      </div>
    </div>
    <div class="appdetail_bot">
      <div class="tab_wrap">
        <div class="tab_item" v-for="(item, index) in tabItems" :key="index" @click="selectTab(index)"
          :class="{ active: selectedIndex === index }">
          {{ item }}
        </div>
      </div>
      <div class="detail_content" ref="detailContent">
        <div class="SpecsDesc_wrap" v-if="info.appSpecAddVos">
          <h1 class="title" ref="tabRefs0">规格说明</h1>
          <div class="specDesc_content">
            <div class="item">
              <div class="label">应用规格</div>
              <div class="value">
                <el-tag class="specItem" v-for="(item,index) in info.appSpecAddVos" :key="index"
                  :class="{specActive: specIndex === index}" @click="specIndex=index">{{item.name}}</el-tag>
              </div>
            </div>
            <div class="item">
              <div class="label">规格说明</div>
              <div class="value">
                {{info.appSpecAddVos[specIndex].deliveryExplain}}
              </div>
            </div>
            <div class="item">
              <div class="label">交付类型</div>
              <div class="value">{{(info.appSpecAddVos[specIndex].deliveryTypeNames).toString()}}</div>
            </div>
            <div class="item">
              <div class="label">交付时间</div>
              <div class="value">{{info.appSpecAddVos[specIndex].deliveryDays}}天</div>
            </div>
          </div>
        </div>
        <div class="detailItem_wrap" v-if="info.appDetailAddVo">
          <h1 class="title" ref="tabRefs1">应用亮点</h1>
          <div class="detailItem_content" v-html="info.appDetailAddVo.lightSpot">

          </div>
        </div>
        <div class="detailItem_wrap" v-if="info.appDetailAddVo">
          <h1 class="title" ref="tabRefs2">应用说明</h1>
          <div class="detailItem_content" v-html="info.appDetailAddVo.appExplain">
          </div>
        </div>
        <div class="detailItem_wrap" v-if="info.appDetailAddVo">
          <h1 class="title" ref="tabRefs3">应用场景</h1>
          <div class="detailItem_content" v-html="info.appDetailAddVo.scene">
          </div>
        </div>
        <div class="detailItem_wrap" v-if="info">
          <h1 class="title" ref="tabRefs4">售后服务</h1>
          <div class="detailItem_content">
            <div class="specDesc_content">
              <div class="item">
                <div class="label">质保期限</div>
                <div class="value">
                  {{info.warrantyPeriod}} {{ warrantyTypeLabel }}
                </div>
              </div>
              <div class="item">
                <div class="label">保障说明</div>
                <div class="value">{{info.explains}}</div>
              </div>
              <div class="item">
                <div class="label">发票类型</div>
                <div class="value">
                  {{ info.invoiceType==1 ? '增值税普通发票' : (info.invoiceType==0 ? '增值税专用发票' : '增值税普通发票/增值税专用发票') }}</div>

              </div>
            </div>
          </div>
        </div>
        <div class="detailItem_wrap">
          <h1 class="title" ref="tabRefs5">相关推荐</h1>
          <div class="detailItem_content">
            <div class='app-item' v-for='(item,index) in recommendList' :key='index' @click='checkDetail(item)'>
              <div class="right_arrow">
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="category">
                <div class="item" v-for='(e,i) in item.sceneTags' :key='e.index'
                  :style="styleStatusColor2(Number(item.sceneTag.split(',')[i]))">{{ e }}</div>
              </div>
              <div class='app-img'>
                <img :src="item.picurl" @error="imageLoadError" />
                <div class="app_type" :style="styleStatusColor(item.appType)">{{ item.appTypeName }}</div>

              </div>
              <div class="item_info">
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="introduce">{{ item.introduce }}</div>
                <div class="companyName">
                  {{item.companyName}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>
<script>
/* eslint-disable */
import commonFooter from '@/components/commonFooter'
import api from '@/api/api'
import defaultImage from '@/assets/images/defaultImg.svg';
export default {
  components: {
    commonFooter,
  },
  created () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.queryInfo();
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll);
  },
  data () {
    return {
      baseUrl: '/oss',
      info: {},
      companyName: this.$route.query.companyName,
      activeTab: '1',
      radio: 0,
      tabItems: ['规格说明', '应用亮点', '应用说明', '应用场景', '售后服务', '相关推荐'],
      selectedIndex: 0,
      specIndex: 0,
      recommendList: [],
      defaultImage,
    }
  },
  computed: {
    styleStatusColor () {
      return function (v) {
        const colorMap = {
          0: '#5b9bd5',
          1: '#ed7d31',
          2: '#70ad47',
          3: '#ffc000',
          4: '#4472c4',
          5: '#b235e6',
          6: '#91d024',
          7: '#02ae75',
        };
        const color = colorMap[v];
        return color ? `border: 1px solid ${color}; color: ${color};` : '';
      }
    },
    styleStatusColor2 () {
      return function (v) {
        const colorMap = {
          0: '#63b2ee',
          1: '#76da91',
          2: '#f8cb7f',
          3: '#f89588',
          4: '#7cd6cf',
          5: '#9192ab',
          6: '#7898e1',
          7: '#efa666',
          8: '#eddd86',
          9: '#9987ce',
          10: '#9787ce',
          11: '#9687ce',
          12: '#b587ce',
          13: '#7487ce',
          14: '#0387ce',
          15: '#5287ce',
          16: '#e187ce',
        };
        const hex = colorMap[v];
        if (!hex) return '';
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        const rgba = `rgba(${r}, ${g}, ${b}, 0.1)`;
        return `border: 1px solid ${hex}; color: ${hex}; background-color: ${rgba};`;
      }
    },
    warrantyTypeLabel () {
      switch (this.info.warrantyType) {
        case 1:
          return '年';
        case 2:
          return '月';
        case 3:
          return '日';
        default:
          return '';
      }
    }
  },
  methods: {
    imageLoadError (event) {
      event.target.src = this.defaultImage;
    },
    checkDetail (e) {
      this.$router.push({
        path: '/appDetail',
        query: {
          id: e.id,
          companyName: e.companyName
        }
      })
      this.$router.go(0);
    },
    queryInfo () {
      api.queryGateWayAppDetail(this.$route.query.id).then(res => {
        if (res.code == 200) {
          this.info = res.data;
          console.log(this.info, 'this.info---')

          this.info.pictureUrl = window.location.origin + '/ecol/app/image?path=/' + this.info.picture;

          this.getRecommendData()
        }
      })
    },
    goUse () {
      if ([3, 4].includes(this.info.appType)) {
        this.$router.push(`/appExperience/${this.info.id}/${this.companyName}/${this.info.appType}`)
      } else {
        window.open(this.info.appDetailAddVo.url, '_blank')
      }

    },
    handleClick () {
    },
    selectTab (index) {
      this.selectedIndex = index;
      this.$refs[`tabRefs${index}`].scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    async getRecommendData () {

      const result = await api.queryGateWayApp({
        appTypes: [this.info.appType],
        pageNum: 1,
        pageSize: 5,

      })
      this.recommendList = result.data.list.map(item => {
        return {
          ...item,
          picurl: window.location.origin + '/ecol/app/image?path=/' + item.picture
        }
      }).filter(item => item.id != this.info.id)
    },
    onScroll () {
      const sections = this.$refs;
      let currentIndex = this.selectedIndex;
      for (let i = 0; i < this.tabItems.length; i++) {
        const section = sections[`tabRefs${i}`];
        const rect = section.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          currentIndex = i;
          break;
        }
      }
      this.selectedIndex = currentIndex;
    }
  }
}
</script>
<style lang="scss" scoped>
.appdetail {
  .appdetail_top {
    position: relative;
    box-sizing: border-box;
    width: 1440px;
    height: 192px;
    margin: 0 auto;
    margin-top: 24px;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    .app_detail_btn {
      position: absolute;
      right: 66px;
      bottom: 16px;
      width: 105px;
      height: 36px;
      background: #2774f7;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;

      color: #ffffff;
    }
    .account_wrap {
      position: absolute;
      right: 18px;
      box-sizing: border-box;
      top: 140px;
      &:hover .account_list {
        display: block;
      }
      img {
        width: 35px;
        height: 35px;
      }
    }
    .account_list {
      z-index: 999;
      display: none;
      position: absolute;
      padding: 0px 16px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 1px 1.73px 12px 0px rgba(0, 0, 0, 0.1);
      .account_item {
        background: #fff;
        margin: 10px 0px;
        > div {
          display: flex;
          margin: 3px;
        }
        .label,
        .value {
          font-size: 12px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: right;
          color: #a8a8a8;
          width: 50px;
        }
        .value {
          color: #333;
        }
      }
    }
    .info_left {
      width: 261px;
      height: 160px;
      margin-right: 24px;
      position: relative;
      overflow: hidden;
      border: 1px solid #ece6e6;
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .info_right {
      padding: 8px 0;
      .info-title {
        font-size: 24px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
      }
      .info-companyName {
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin-top: 17px;
      }
      .info-intrudce {
        margin-top: 19px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;
        color: #a8abb2;
        width: 940px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* 限制在5行 */
        overflow: hidden;
      }
      .info-bottom {
        bottom: 18px;
        position: absolute;
        display: flex;
        .app-tag {
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN-Medium;
          font-weight: 500;
          text-align: left;
          color: #2774f7;
          margin-right: 20px;
        }
      }
    }
    .category {
      display: flex;
      position: absolute;
      top: 24px;
      right: 0;
      .item {
        background: #fff6f3;
        border: 1px solid #f75327;
        border-radius: 4px;
        font-size: 12px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #f75327;
        padding: 7px 12px;
        margin-right: 16px;
      }
      .item.b {
        background: #eaf2ff;
        border: 1px solid #2774f7;
        color: #2774f7;
      }
    }
  }

  .appdetail_bot {
    width: 1440px;
    background: #ffffff;
    border-radius: 8px;
    margin: 16px auto 33px;

    .tab_wrap {
      display: flex;
      width: 1408px;
      height: 36px;
      background: #fff;
      border-radius: 4px;
      align-items: center;
      font-size: 14px;
      color: #5c5c5c;
      .tab_item {
        padding: 0 30px 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
      }
      .tab_item.active {
        font-size: 16px;
        text-align: left;
        color: #ffffff;
        background: url("~@/assets/images/tab_active.png") 100% no-repeat;
      }
    }
    .detail_content {
      box-sizing: border-box;
      margin-top: 20px;
      padding: 0 30px;
      width: 1440px;
      /* height: 480px;
      overflow: hidden;
      overflow-y: auto; */
      box-sizing: border-box;
      padding: 40px;

      .title {
        text-align: center;
        font-size: 32px;
      }
      .specDesc_content {
        .item {
          width: 1200px;
          margin: 30px auto;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #000;
          .label {
            font-weight: 700;
            width: 120px;
          }
          .value {
            display: flex;
            margin-left: 20px;
            max-width: 1100px;
            color: #8d8c8c;
            .specItem {
              margin-right: 20px;
              cursor: pointer;
            }
          }
        }
      }
      .detailItem_wrap {
        width: 1200px;
        margin: 0 auto;
        margin-top: 70px;
        font-size: 16px;
        .detailItem_content {
        }
      }
      .app-item {
        position: relative;
        display: flex;
        margin-bottom: 16px;
        box-sizing: border-box;
        width: 1200px;
        height: 156px;
        margin: 0 auto;
        margin-top: 20px;
        background: #ffffff;
        border-radius: 8px;
        padding: 16px;
        letter-spacing: 0;
        text-indent: 0;
        box-shadow: 1px 1.73px 12px 0px rgba(0, 0, 0, 0.1);
        .right_arrow {
          position: absolute;
          right: 18px;
          bottom: 20px;
          font-size: 18px;
          color: #a8a8a8;
          cursor: pointer;
        }
        .category {
          position: absolute;
          right: 0px;
          top: 18px;
          display: flex;
          .item {
            margin-right: 18px;
            padding: 7px 17px;
            border-radius: 4px;
            font-size: 12px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 700;
          }
        }
        .app-img {
          cursor: pointer;
          width: 148px;
          height: 124px;
          position: relative;
          margin-right: 16px;
          position: relative;
          border: 1px solid #ece6e6;
          img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .app_type {
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 12px !important;
            background: #ffffff;
            border-radius: 4px;
            padding: 4px;
            text-align: left;
          }
        }

        .item_info {
          box-sizing: border-box;
          flex: 1;
          padding: 9px 0;
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: left;
            color: #2774f7;
            margin-bottom: 23px;
          }
          .introduce {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #a8abb2;
            margin-bottom: 34px;
            white-space: nowrap; /* 保持文本在一行 */
            overflow: hidden; /* 隐藏溢出的内容 */
            text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
            max-width: 1016px;
          }
          .companyName {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
          }
        }
      }
    }
    .specActive {
      border: 1px solid #40b6ff;
    }
  }
}
.introduce_tooltip,
.rejectContent_tooltip {
  max-width: 900px;
  background: #fff;
  color: #000;
  display: inline-block;
  font-size: 14px;
}
</style>

